import React, { useState, useEffect } from 'react';
import {
  Card, Button, Spinner, Modal, Form, Alert,
} from 'react-bootstrap';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { FleetUserAPIUrl, FleetUserAPILoginError } from '../GlobalDefine';
import i18n from '../../i18n';
import { GetLoginInfo, SetLoginInfo } from '../../LoginInfo';
import LogoImg from '../../textures/materials/logo_en.png';
import AccountImg from '../../textures/materials/login_icn/icn_member.png';
import PasswordImg from '../../textures/materials/login_icn/icn_password.png';
import '../css/Textfield.css';
import '../css/LoginPage.css';
import { auth } from '../../firebase';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';

const Login = (props) => {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [loginStatus, setLoginStatus] = useState((GetLoginInfo() && GetLoginInfo().loginStatus) || false);
  const [showErrorText, setShowErrorText] = useState(false);
  const [showLoginErrorModal, setShowLoginErrorModal] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [unlockTime, setUnlockTime] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const controller = new AbortController();
  const callback = props.onUpdateName;

  useEffect(() => {
    loadCaptchaEnginge(6); // 初始化验证码引擎
    return () => {
      setShowErrorText(false);
    };
  }, []);

  const onFormChange = (event) => {
    const { name, value } = event.target;
    if (name === 'account') setAccount(value);
    if (name === 'password') setPassword(value);
    if (name === 'captchaInput') setCaptchaInput(value);
  };

  const onFormSubmit = async () => {
    if (!validateCaptcha(captchaInput)) {
      alert(i18n.t('login_error_captcha'));
      setCaptchaInput(''); // 清除用户输入的验证码
      return;
    }

    // 如果輸入帳號、密碼符合「後門帳號」與「後門密碼」，跳過 Firebase 驗證
    if (account === process.env.REACT_APP_BACKDOOR_ACCOUNT
      && password === process.env.REACT_APP_BACKDOOR_PASSWORD) {
      // 直接模擬登入狀態
      const userData = {
        account: process.env.REACT_APP_BACKDOOR_ACCOUNT,
        userId: process.env.REACT_APP_BACKDOOR_UID,
        companyId: '9999',
        loginStatus: true,
      };
      SetLoginInfo(userData);
      localStorage.setItem('loginInfo', JSON.stringify(userData));
      callback();
      setLoginStatus(true);
      setLoginAttempts(0); // 清除累積的嘗試次數
      return;
    }

    try {
      // 获取账户解锁时间
      await login();
    } catch (error) {
      console.error('获取解锁时间失败:', error);
      // 登录失败，记录登录尝试次数
      setLoginAttempts(prevAttempts => prevAttempts + 1);
      setShowLoginErrorModal(true);

      if (loginAttempts >= 5) {
        try {
          // 记录登录错误
          await logLoginError(account);
        } catch (logError) {
          console.error('记录登录错误失败:', logError);
        } finally {
          setLoginAttempts(0); // 重置登录尝试次数
        }
      }
    }
  };

  const login = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, account, password);
      const { user } = userCredential;
      const token = await user.getIdToken();

      setLoginStatus(true);

      localStorage.setItem('user', JSON.stringify(user));
      const fetchPath = `${FleetUserAPIUrl}getCompanyIdByUserId?userId=${user.uid}`;

      axios.get(fetchPath, {
        headers: {
          Authorization: `${token}`,
        },
        signal: controller.signal,
      }).then((response) => {
        const userData = {
          account,
          userId: user.uid,
          companyId: response.data.data.company_id,
          loginStatus: true,
        };

        SetLoginInfo(userData);
        localStorage.setItem('loginInfo', JSON.stringify(GetLoginInfo()));
        callback();
        setLoginStatus(GetLoginInfo().loginStatus);
      });
    } catch (error) {
      console.log(error.message);

      if (error.code === 'auth/too-many-requests') {
        setLoginStatus(false);
        setShowLoginErrorModal(true);
        setLoginErrorMessage(`${i18n.t('login_error_log_1')} 30 ${i18n.t('login_error_log_2')}`);
      } else {
        setLoginStatus(false);
        setShowLoginErrorModal(true);
        setLoginErrorMessage(i18n.t('login_username_or_password_incorrect'));
      }
    }
  };

  const logLoginError = async (account) => {
    // 获取客户端IP地址
    const ipAddress = await axios.get('https://api.ipify.org?format=json')
      .then((response) => response.data.ip)
      .catch((fetchError) => {
        console.error('获取IP地址失败:', fetchError);
        return null;
      });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, account)
      .then(() => {
        alert(i18n.t('reset_email_sent'));
      })
      .catch((err) => {
        alert(i18n.t('reset_email_failed'));
        console.error('发送重置邮件错误:', err.message);
      });
  };

  const handleResetPasswordClick = (e) => {
    if (e.key === 'Enter') {
      resetPassword(e);
    }
  };

  const handleCloseLoginErrorModal = () => {
    setShowLoginErrorModal(false);
  };

  return (
    <div className="login signin-container">
      <div className="login column">
        <a href="https://web.mindtronicai.com">
          <img className="login logo-image" alt="" src={LogoImg} />
        </a>
        {!loginStatus && (
          <div className="login form-container">
            {unlockTime && (
              <Alert variant="danger">
                {i18n.t('login_page_account')} {unlockTime.toLocaleString()} {i18n.t('login_page_account')}
              </Alert>
            )}
            <div className="basic-row top-control-margin relative">
              <input
                className="textfield-large"
                type="account"
                name="account"
                placeholder={i18n.t('login_page_account')}
                value={account}
                onChange={onFormChange}
                id="input-placeholder"
                required
              />
              <img className="login image" alt="" src={AccountImg} />
            </div>
            <div className="basic-row top-control-margin relative">
              <input
                className="textfield-large"
                type="password"
                name="password"
                placeholder={i18n.t('login_page_password')}
                value={password}
                onChange={onFormChange}
                id="input-placeholder"
                required
              />
              <img className="login image" alt="" src={PasswordImg} />
            </div>
            <Form.Group controlId="captcha" className="basic-row top-control-margin relative captcha-group">
              <div className="captcha-container">
                <LoadCanvasTemplate reloadColor="white" />
                <Form.Control
                  type="text"
                  name="captchaInput"
                  placeholder="Enter Captcha"
                  value={captchaInput}
                  onChange={onFormChange}
                  style={{ marginLeft: '2.5rem' }}
                  required
                />
              </div>
            </Form.Group>
            <Button
              className="login login-button"
              variant="success"
              type="button"
              onClick={onFormSubmit}
            >
              {i18n.t('login')}
            </Button>
            {showErrorText && (
              <Card.Text id="fade-in" className="login error-text">
                Wrong ID or Password, please enter again.
              </Card.Text>
            )}
            <div className="forgot-password-link">
              <span
                onClick={resetPassword}
                onKeyDown={handleResetPasswordClick}
                tabIndex={0}
                role="button"
              >
                {i18n.t('forget_password')}
              </span>
            </div>
          </div>
        )}
        {GetLoginInfo() && loginStatus && GetLoginInfo().companyId !== '' && (
          <div>
            <div className="spinner-container">
              <Spinner
                className="spinner"
                animation="border"
              />
            </div>
            <Redirect to="/fleet" />
          </div>
        )}

        {/* Login Error Modal */}
        <Modal show={showLoginErrorModal} onHide={handleCloseLoginErrorModal}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t('login_error')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loginErrorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseLoginErrorModal}>
              {i18n.t('login_error_close')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Login;
